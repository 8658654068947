// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";

// mobx
import { observer } from "mobx-react";

// fragments
import { fragments } from "services/get-fragments";

// react-router
import { Link } from "react-router-dom";

// semantic-ui
import { Header, Grid, Image, Button, Item, Checkbox, Container } from "semantic-ui-react";

import CheckboxChecked from "components/Checkbox"


export const HomePage = observer(() => (
  <React.Fragment>
    <Grid container style={{ marginTop: "30px" }}>
      <Container>
        <h3>Overview</h3>
           <br />
            <Grid className="finchcard">
                <Header as="h1" style={{ paddingTop: "15px" }}>Welcome to the FinchXP Developer Portal</Header>
                <Grid.Column width={5} widescreen={8}>
                <p>
                The FinchXP Developer Portal is where you’ll find everything you need to manage your API. Follow the steps below to get started.
                </p>
                <Header as="h3">Getting Started Checklist</Header>
                <Item.Group divided>
                <Item>
                    <Item.Content verticalAlign='middle'><Checkbox /><label style={{paddingLeft: "30px", fontSize:"1em", color: "#1e2783"}}>Create a FinchXP account</label></Item.Content>
                  </Item>
                  <Item>
                    <Item.Content verticalAlign='middle'><Checkbox /><label style={{paddingLeft: "30px", fontSize:"1em", color: "#1e2783"}}>Select a Product in the side menu</label></Item.Content>
                  </Item>

                  <Item>
                    <Item.Content verticalAlign='middle'><Checkbox /><label style={{paddingLeft: "30px", fontSize:"1em", color: "#1e2783"}}>Subscribe to a plan</label></Item.Content>
                  </Item>
                  <Item>
                    <Item.Content verticalAlign='middle'><Checkbox /><label style={{paddingLeft: "30px", fontSize:"1em", color: "#1e2783"}}>Send your first request in Swagger or Postman</label></Item.Content>
                  </Item>
                </Item.Group>
              </Grid.Column>
              {/* <Grid.Column
                width={5}
                widescreen={8}
                style={{ paddingLeft: "50px", marginBottom: '20px' }}
              >
                <Image
                  right
                  src="/custom-content/Welcome.png"
                  width="70%"
                  height="80%"
                  style={{ paddingRight: "0px", marginTop: '50px', verticalAlign: 'middle' }}
                />
              </Grid.Column> */}
            </Grid>
      </Container>
    </Grid>
  </React.Fragment>
));

export default HomePage;
