// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";

// mobx
import { observer } from "mobx-react";

// fragments
import { fragments } from "services/get-fragments";

// react-router
import { Link } from "react-router-dom";

// semantic-ui
import { Header, Grid, Image, Button, Container } from "semantic-ui-react";

export const HomePage = observer(() => (

  <React.Fragment>
    <Grid container style={{ marginTop: "30px", marginBottom: "10px"  }}>
      <Container>
        <h3>Products</h3>
        <br />
        <Grid className="finchcard" style={{paddingBottom: "30px"}}>
          <Grid.Column width={5} widescreen={8}>
            <Header as="h1">Rewards</Header>
            <h3>Seamless rewards for discount and cashback programs.</h3>
            <p>Our Rewards Solution lets you retrieve accurate merchant identification, categorisation and location to provide real-time identification of eligible in-store and online purchases. The Rewards Solution is currently only available for Enterprise customers.</p>
            <h4>
              To subscribe to a “Production” plan, please request access
              {" "}
              <a
                href="https://finchxp.com/request-form-production/"
                rel="noopener"
                target="_blank"
              >
                here.
              </a>
            </h4>
            <p>
              To discuss a custom solution, please contact our sales team.
            </p>
            <br></br>
              <Button href="https://finchxp.com/contact/" rel="noopener" target="_blank">Schedule Demo</Button>         
          
          </Grid.Column>
          {/* <Grid.Column width={5} widescreen={8} style={{ paddingLeft: "50px" }}>
            <Image
              right
              src="/custom-content/Loyalty.png"
              width="75%"
              height="95%"
              style={{ alignItem: "right" }}
            />
          </Grid.Column> */}
        </Grid>
      </Container>
    </Grid>
  </React.Fragment>
));

export default HomePage;
