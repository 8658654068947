// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";

// mobx
import { observer } from "mobx-react";

// fragments
import { fragments } from "services/get-fragments";

// react-router
import { Link } from "react-router-dom";

// semantic-ui
import { Header, Grid, Image, Button, Container } from "semantic-ui-react";

import ProductSubscription from "components/ProductSubscription";


export const HomePage = observer(() => (

  <React.Fragment>
    <Grid container style={{ marginTop: "30px", marginBottom: "10px"  }}>
      <Container>
        <h3>Products</h3>
        <br />
        <Grid className="finchcard">
          <Grid.Column
            width={5}
            widescreen={8}
            style={{ marginBottom: "20px" }}
          >
            <Header as="h1">Personal Finance</Header>
            <h3>Empower customers to make better financial decisions.</h3>
            <p>Our Personal Finance Solution lets you retrieve accurate information around a customer’s transactions to better understand their financial activities and overall position.</p>
            <p>In addition to over 240+ categories including 32 income categories, we provide over 25,000 logos, along with merchant name, location, category and website.</p>
            <h4>
              To subscribe to a “Production” plan, please request access 
              {" "}
              <a
                href="https://finchxp.com/request-form-production/"
                rel="noopener"
                target="_blank"
              >
                here.
              </a>
            </h4>
            <ProductSubscription
              product="pfm"
              style={{ marginTop: "50px !important" }}
            />
            <br></br>
            <p style={{fontSize:'11px'}}>By clicking “Start Testing” you indicate that you have read and agree to our <a href='https://finchxp.com/terms_of_service/' target="_blank">Terms and Conditions of Service.</a></p>

          </Grid.Column>
          {/* <Grid.Column width={5} widescreen={8} style={{ paddingLeft: "50px" }}>
            <Image
              right
              src="/custom-content/personal-finance.png"
              width="75%"
              height="75%"
            />
          </Grid.Column> */}
        </Grid>
        {/* <br></br>
        <br></br>
        <Container className="banner">
          <p style={{ color: "white" }}>
            COVID-19 Update: due to the deteriorating financial situation of
            millions of Australians, financial literacy is more important than
            ever. To help support your customers through this time, the Personal
            Finance API will be available free for up to 1000 end-users for a
            limited time
          </p>
          <Button
            href="https://finchxp.com/request-form-promo/"
            data-btn="true"
            rel="noreferrer"
            target="_blank"
          >
            Request Promo Access
          </Button> 
        </Container> */}
      
      <br />
      <br />

      <Grid className="tutorial">
        <Grid.Row>
        <Header as='h3'>Ready to Start Testing? </Header>
        <p>For easy integration, we have published Swagger and Postman collections that provide examples for all the FinchXP API endpoints.
           For instructions on how to get started, watch our 2-min videos or read our Quickstart Guide <a href="https://docs.finchxp.com/#topic-Quickstart-Guide" rel="noopener" target="_blank"> here.</a> 
        </p>
        </Grid.Row>
          <Grid.Column width={8} widescreen={8}style={{ marginBottom: "20px", marginLeft: "-15px"}}>
            <Header as='h3'>Swagger UI</Header>
            <p>
            To get started in Swagger UI, simply go to the API References page in the sidebar.
            </p>
            <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen" data-hj-allow-iframe="" frameborder="0" height="315" src="https://player.vimeo.com/video/421396407" width="560" data-gtm-yt-inspected-30294834_60="true" id="690512011" width="90%" height="100%" ></iframe>
          </Grid.Column>
          <Grid.Column width={2} widescreen={8} style={{ marginBottom: "20px"}}>
          <Header as='h3'>Postman</Header>
            <p>
            To get started on Postman, simply import the FinchXP Postman collection by clicking <a href="https://app.getpostman.com/run-collection/16132809-bc3220c5-db9a-475c-9e55-eca03be4a607?action=collection%2Ffork&collection-url=entityId%3D16132809-bc3220c5-db9a-475c-9e55-eca03be4a607%26entityType%3Dcollection" rel="noopener" target="_blank"> here.</a> 
            </p>
            <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen" data-hj-allow-iframe="" frameborder="0" height="315" src="https://player.vimeo.com/video/419779190" width="560" data-gtm-yt-inspected-30294834_60="true" id="690512011" width="90%" height="100%" ></iframe>
          </Grid.Column>
      </Grid> 
    </Container> 
    </Grid>

  </React.Fragment>
));

export default HomePage;
