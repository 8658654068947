// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import {
  Grid,
  Header,
  Container,
  Button,
  Table,
  Input,
} from "semantic-ui-react";

import {
  createNewApiClient,
  updateApiClient,
  deleteApiClient,
} from "services/api-catalog";

import { observer } from "mobx-react";

import { store } from "services/state";
import { Link } from "react-router-dom";

import _ from "lodash";

const createApiClient = () => {
  const description = prompt("New API Client Description", "My new API Client");
  if (description == null) {
    return;
  }
  createNewApiClient(description);
};
const modifyApiClient = (apiClient) => {
  const description = prompt(
    "Change API Client Description",
    apiClient.description
  );
  if (description == null) {
    return;
  }
  updateApiClient({
    ...apiClient,
    description,
  });
};
const confirmDeleteApiClient = (apiClient) => {
  if (
    !window.confirm(
      "Are you sure you want to delete this API Client? Any existing integration will be disrupted"
    )
  ) {
    return;
  }
  deleteApiClient(apiClient);
};

export default observer(() => {
  return (
    <Grid container style={{ marginTop: "30px" }}>
    <Container>
      <h3>Authentication</h3>
      <br />
     
    <Grid className="authentication" >
          <Header as="h1">Secure Credentials</Header>
          <p>
            To access FinchXP APIs, we need to authenticate your account
            using your API credentials, which you can create, view and manage on
            this page.
          </p>
          <p>
            For more information about API access and authentication, check our
            Documentation{" "}
            <a href="https://docs.finchxp.com/#topic-Authentication" rel="noopener" target="_blank">
              here.
            </a>
          </p>
        </Grid>
        <br />
        <br />
        <Container className="authentication">
          <p
            style={{
              fontWeight: "600",
              fontSize: "15px",
              paddightLeft: "10px",
            }}
          >
            API Key
          </p>
          <Input
            className="input--code__readonly"
            type="text"
            onFocus={(e) => {
              e.target.select();
            }}
            value={store.apiKey}
            readOnly
          />
          <br></br>
          <br></br>
          <br></br>
          <p
            style={{
              fontWeight: "600",
              fontSize: "15px",
              paddightLeft: "10px",
            }}
          >
            Client Credentials
          </p>

          {store.apiClients ? (
            <div>
              {store.apiClients.map((apiClient) => (
                <Table key={apiClient.clientId} className="table__api-client">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className="cell__header">
                        Client ID
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Input
                          className="input--code__readonly"
                          type="text"
                          onFocus={(e) => {
                            e.target.select();
                          }}
                          value={apiClient.clientId}
                          readOnly
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell className="cell__header">
                        Client Secret
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Input
                          className="input--code__readonly"
                          type={apiClient.revealPassword ? "text" : "password"}
                          value={apiClient.clientSecret}
                          onFocus={(e) => {
                            apiClient.revealPassword = true;
                            e.target.select();
                          }}
                          onBlur={() => {
                            apiClient.revealPassword = false;
                          }}
                          readOnly
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className="cell__header">
                        Description
                      </Table.Cell>
                      <Table.Cell>{apiClient.description}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className="cell__header">
                        Created at
                      </Table.Cell>
                      <Table.Cell>{apiClient.created}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell className="cell__header">
                        Last used
                      </Table.Cell>
                      <Table.Cell>
                        {apiClient.lastAuth ? apiClient.lastAuth : "N/A"}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell />
                      <Table.Cell>
                        <Button onClick={() => modifyApiClient(apiClient)}>
                          <i className="far fa-fw fa-edit" />
                          &nbsp;Edit
                        </Button>
                        <Button
                          onClick={() => confirmDeleteApiClient(apiClient)}
                          negative={true}
                        >
                          <i className="far fa-fw fa-trash-alt" />
                          &nbsp;Delete
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              ))}
            </div>
          ) : (
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan="5">
                    No API client created yet.
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          )}
          <br />
          <Button
            positive
            style={{ color: "white", marginBottom: "1em" }}
            onClick={createApiClient}
          >
            Generate new credentials
          </Button>
        </Container>
      </Container>
    </Grid>
  );
});
