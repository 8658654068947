// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import _ from "lodash";
import { DateTime, Interval, Duration } from "luxon";
import { apiGatewayClient } from "./api";
import { store } from "./state";
import { isAdmin } from "./self";

/* Catalog and API Utils */

/**
 *
 * Does all operations to get user data at once.
 *
 * @param {Boolean} bustCache=true   Ignore the cache and re-make the calls? Defaults to true.
 */
export function updateAllUserData(bustCache = true) {
  let promises = [
    updateProductAndUsagePlanMetadata(),
    getUserSubscribedUsagePlan(),
    updateUsagePlansAndApisList(bustCache),
    updateSubscriptions(bustCache),
    updateApiKey(bustCache),
    updateApiClientDetails(bustCache),
    // loadResourceUsages()
  ];

  if (isAdmin()) promises.push(updateVisibility(bustCache));

  return Promise.all(promises);
}

/**
 *
 * Update the catalog for the current user. Both request and response are cached, so unless the cache is busted, this should only ever make one network call.
 *
 * @param {Boolean} [bustCache=false]   Ignore the cache and re-make the network call. Defaults to false.
 *
 */
export function updateUsagePlansAndApisList(bustCache = false) {
  // if we've already tried, just return that promise
  if (!bustCache && catalogPromiseCache) return catalogPromiseCache;

  store.apiList.loaded = false;

  return (catalogPromiseCache = apiGatewayClient()
    .then((apiGatewayClient) => apiGatewayClient.get("/catalog", {}, {}, {}))
    .then(({ data = [] }) => {
      store.usagePlans = data.apiGateway;
      store.apiList = {
        loaded: true,
        apiGateway: getApiGatewayApisFromUsagePlans(store.usagePlans), // MUST create
        generic: data.generic,
      };
    })
    .catch(() => {
      store.usagePlans = null;
      store.apiList = {
        loaded: true,
        apiGateway: [],
        generic: [],
      };
    }));
}
let catalogPromiseCache; // WARNING: Don't touch this. Should only be used by updateCatalogAndApisList.

/**
 * A function that takes an input usage plans and creates an list of apis out of it.
 *
 * - Makes sure each api has a non-recursive 'usagePlan' object
 *
 * returns an array of apis
 */
function getApiGatewayApisFromUsagePlans(usagePlans) {
  return usagePlans.reduce((acc, usagePlan) => {
    usagePlan.apis.forEach((api) => {
      api.usagePlan = _.cloneDeep(usagePlan);
      // remove the apis from the cloned usagePlan so we don't go circular
      delete api.usagePlan.apis;
    });

    return acc.concat(usagePlan.apis);
  }, []);
}

/**
 * Return the API with the provided apiId. Can also provide the special strings "FIRST" or "ANY" to get the first API returned. Can select the api returned as a side-effect.
 *
 * @param {String} apiId   An apiId or the special strings 'FIRST' or 'ANY'. 'FIRST' and 'ANY' both return the first api encountered.
 * @param {Boolean} [selectIt=false]   If true, sets the found API as the current 'selected' API.
 */
export function getApi(apiId, selectIt = false, stage, cacheBust = false) {
  return updateUsagePlansAndApisList(cacheBust).then(() => {
    let thisApi;

    let allApis = [].concat(store.apiList.apiGateway, store.apiList.generic);

    if (allApis.length) {
      if (apiId === "ANY" || apiId === "FIRST") {
        thisApi = allApis[0];
      } else {
        thisApi = allApis.find((api) => api.id.toString() === apiId);
      }

      if (stage) {
        thisApi = store.apiList.apiGateway.find(
          (api) => api.id.toString() === apiId && api.stage === stage
        );
      }
    }

    if (selectIt) store.api = thisApi;

    return thisApi;
  });
}

export function updateVisibility(cacheBust = false) {
  return apiGatewayClient()
    .then((app) => app.get("/admin/catalog/visibility", {}, {}, {}))
    .then(({ data }) => (store.visibility = data));
}

/* Subscription Utils */

/**
 * Fetch and update subscriptions store. Uses caching to determine if it should actually fetch or return the stored result.
 *
 * @param {Boolean} [bustCache=false]   Ignore the cache and re-make the network call. Defaults to false.
 */
export function updateSubscriptions(bustCache = false) {
  let subscriptionsOrPromise = store.subscriptions.length
    ? store.subscriptions
    : subscriptionsPromiseCache;
  if (!bustCache && subscriptionsOrPromise)
    return Promise.resolve(subscriptionsOrPromise);

  return (subscriptionsPromiseCache = apiGatewayClient()
    .then((apiGatewayClient) =>
      apiGatewayClient.get("/subscriptions", {}, {}, {})
    )
    .then(({ data }) => (store.subscriptions = data)));
}
let subscriptionsPromiseCache; // WARNING: Don't touch this. Should only be used by updateCatalogAndApisList.

export function getSubscribedUsagePlan(usagePlanId) {
  return store.subscriptions.find((sub) => sub.id === usagePlanId);
}

export function subscribe(usagePlanId) {
  return apiGatewayClient()
    .then((apiGatewayClient) =>
      apiGatewayClient.put("/subscriptions/" + usagePlanId, {}, {})
    )
    .then(() => updateSubscriptions(true));
}

export function unsubscribe(usagePlanId) {
  return apiGatewayClient()
    .then((apiGatewayClient) =>
      apiGatewayClient.delete(`/subscriptions/${usagePlanId}`, {}, {})
    )
    .then(() => updateSubscriptions(true));
}

export const fetchToXpAdminApi = async (endpoint, params = {}) => {
  const region = window.config.identityPoolId.split(":")[0];
  const userPoolId = window.config.userPoolId;
  const cognitoPoolId = `cognito-idp.${region}.amazonaws.com/${userPoolId}`;

  const jwt = window.AWS.config.credentials.params.Logins[cognitoPoolId];
  if (!params.headers) params.headers = {};
  params.headers.Authorization = `Bearer ${jwt}`;
  return fetch(`${window.config.xpEndpoint}${endpoint}`, params);
};

export const syncUsagePlanToCognito = async () => {
  const response = await fetchToXpAdminApi("/admin/usagePlans/sync", {
    method: "POST",
  });
  const syncedGroups = await response.json();
  return syncedGroups;
};

export const subscribeUserToUsagePlan = async (usagePlanId) => {
  const response = await fetchToXpAdminApi(
    `/admin/usagePlans/subscribe/${usagePlanId}`,
    { method: "POST" }
  );
  const subscribeResults = await response.json();
  store.subscribedUsagePlans = null;
  getUserSubscribedUsagePlan();
  if (subscribeResults) {
    alert("Successfully subscribed");
  }
};
export const getUserSubscribedUsagePlan = async () => {
  const response = await fetchToXpAdminApi("/admin/usagePlans/subscribed");
  const subscribedUsagePlans = await response.json();
  store.subscribedUsagePlans = subscribedUsagePlans;
  console.log(subscribedUsagePlans);
};

export const updateProductAndUsagePlanMetadata = async () => {
  const response = await fetchToXpAdminApi("/admin/products/metadata");
  const productMetadata = await response.json();
  store.productMetadata = productMetadata;
};

export const loadResourceUsages = async () => {
  const response = await fetchToXpAdminApi("/api/finchxp/admin/daily-usage");
  const usageReport = await response.json();

  const {
    totalTransactions,
    totalUsers,
    totalJobs,
    dateFrom,
    dateTo,
    details,
  } = usageReport;
  const dtFrom = DateTime.fromISO(dateFrom);
  const dtTo = DateTime.fromISO(dateTo);
  const usagePlans = Object.keys(totalTransactions ? totalTransactions : {});
  const productTypes = [];
  const jobProcessingTypes = [];
  Object.keys(totalUsers ? totalUsers : {}).forEach((u) => {
    if (usagePlans.indexOf(u) < 0) usagePlans.push(u);
  });

  usagePlans.forEach((p) => {
    Object.keys(totalTransactions[p]).forEach((lvl) => {
      if (productTypes.indexOf(lvl) < 0) productTypes.push(lvl);
    });
  });

  usagePlans.forEach((p) => {
    if (!totalJobs[p]) return;
    productTypes.forEach((eL) => {
      if (!totalJobs[p][eL]) return;
      Object.keys(totalJobs[p][eL]).forEach((processingType) => {
        if (jobProcessingTypes.indexOf(processingType) < 0) {
          jobProcessingTypes.push(processingType);
        }
      });
    });
  });

  const dailyParts = {};

  Interval.fromDateTimes(dtFrom, dtTo)
    .splitBy(Duration.fromObject({ day: 1 }))
    .map((d) => d.start.toISODate())
    .forEach((d) => (dailyParts[d] = null));

  const xLabels = Object.keys(dailyParts).sort();

  const presets = {
    red: "rgb(255, 99, 132)",
    orange: "rgb(255, 159, 64)",
    yellow: "rgb(255, 205, 86)",
    green: "rgb(75, 192, 192)",
    blue: "rgb(54, 162, 235)",
    purple: "rgb(153, 102, 255)",
    grey: "rgb(201, 203, 207)",
  };

  const transparentize = (color, opacity) => {
    var alpha = opacity === undefined ? 0.5 : 1 - opacity;
    return global.Color(color).alpha(alpha).rgbString();
  };

  const enLevelColours = {
    PRO: {
      backgroundColor: transparentize(presets.blue),
      borderColor: presets.blue,
    },
    BASIC: {
      backgroundColor: transparentize(presets.green),
      borderColor: presets.green,
    },
    DEFAULT: {
      backgroundColor: transparentize(presets.grey),
      borderColor: presets.grey,
    },
  };
  const processingTypeColours = {
    "PRO-EXPEDITED": {
      backgroundColor: transparentize(presets.blue),
      borderColor: presets.blue,
    },
    "PRO-STANDARD": {
      backgroundColor: transparentize(presets.orange),
      borderColor: presets.blue,
    },
    "BASIC-EXPEDITED": {
      backgroundColor: transparentize(presets.green),
      borderColor: presets.blue,
    },
    "BASIC-STANDARD": {
      backgroundColor: transparentize(presets.yellow),
      borderColor: presets.blue,
    },
    DEFAULT: {
      backgroundColor: transparentize(presets.grey),
      borderColor: presets.grey,
    },
  };

  const commonStyles = {
    pointBackgroundColor: "transparent",
    pointBorderColor: "transparent",
    borderRadius: "10px",
    borderWidth: 2,
    pointRadius: 10,
    pointHoverRadius: 10,
  };
  var data = ["transactions", "users", "jobs"].map((type) => ({
    labels: xLabels,
    resourceType: type,
    datasets: productTypes
      .map((productType) => {
        return type !== "jobs"
          ? {
              data: xLabels.map((date) => {
                return dailyParts[date] &&
                  dailyParts[date][type] &&
                  dailyParts[date][type][productType]
                  ? dailyParts[date][type][productType]
                  : 0;
              }),
              ...commonStyles,
              ...(enLevelColours[productType]
                ? enLevelColours[productType]
                : enLevelColours["DEFAULT"]),
              label: productType,
              fill: "origin",
            }
          : jobProcessingTypes.map((processingType) => ({
              data: xLabels.map((date) => {
                return dailyParts[date] &&
                  dailyParts[date][type] &&
                  dailyParts[date][type][productType] &&
                  dailyParts[date][type][productType][processingType]
                  ? dailyParts[date][type][productType][processingType]
                  : 0;
              }),
              ...commonStyles,
              ...(processingTypeColours[`${productType}-${processingType}`]
                ? processingTypeColours[`${productType}-${processingType}`]
                : enLevelColours["DEFAULT"]),
              label: `${productType} (${processingType})`,
              fill: "origin",
            }));
      })
      .reduce((p, n) => (n instanceof Array ? p.concat(n) : p.concat([n])), []),
  }));

  return (store.usageReport = {
    dtFrom,
    dtTo,
    data,
    usagePlans,
    productTypes,
    totalTransactions,
    totalUsers,
    totalJobs,
    jobProcessingTypes,
  });
};

export const updateApiClientDetails = async (bustCache) => {
  const response = await fetchToXpAdminApi("/auth/admin/apiClients");
  const apiClients = await response.json();
  store.apiClients = apiClients.clients;
};

export const deleteApiClient = async (apiClient) => {
  const response = await fetchToXpAdminApi(
    `/auth/admin/apiClients/${apiClient.clientId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  await response.json();
  updateApiClientDetails(true);
};

export const updateApiClient = async (apiClient) => {
  const response = await fetchToXpAdminApi(
    `/auth/admin/apiClients/${apiClient.clientId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiClient),
    }
  );
  const createResults = await response.json();
  if (createResults.clientId) {
    updateApiClientDetails(true);
  }
};

export const createNewApiClient = async (description) => {
  const response = await fetchToXpAdminApi("/auth/admin/apiClients", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      description,
    }),
  });
  const createResults = await response.json();
  if (createResults.clientId) {
    updateApiClientDetails(true);
    alert("New API Client created");
  }
};

/**
 *
 * Fetches and updates the apiKey in the store. Both request and response are cached, so unless the cache is busted, this should only ever make one network call.
 *
 */
export function updateApiKey(bustCache) {
  let apiKeyOrPromise = store.apiKey ? store.apiKey : apiKeyPromiseCache;
  if (!bustCache && apiKeyOrPromise) return Promise.resolve(apiKeyOrPromise);

  return apiGatewayClient()
    .then((apiGatewayClient) => apiGatewayClient.get("/apikey", {}, {}, {}))
    .then(({ data }) => (store.apiKey = data.value))
    .catch(() => updateApiKey());
}
let apiKeyPromiseCache;

export function fetchUsage(usagePlanId) {
  const date = new Date();
  const start = new Date(date.getFullYear(), date.getMonth(), 1)
    .toJSON()
    .split("T")[0];
  const end = date.toJSON().split("T")[0];
  return apiGatewayClient().then((apiGatewayClient) =>
    apiGatewayClient.get(
      "/subscriptions/" + usagePlanId + "/usage",
      { start, end },
      {}
    )
  );
}

export function mapUsageByDate(usage, usedOrRemaining) {
  const dates = {};
  Object.keys(usage.items).forEach((apiKeyId) => {
    const apiKeyUsage = mapApiKeyUsageByDate(
      usage.items[apiKeyId],
      usage.startDate,
      usedOrRemaining
    );

    // handles the bizarre case that the user has more than one api key
    // currently not possible (and my never be), so we probably don't need it
    apiKeyUsage.forEach((dailyUsage) => {
      const date = dailyUsage[0];
      const used = dailyUsage[1];
      const remaining = dailyUsage[2];

      if (!dates[date]) dates[date] = { used: 0, remaining: 0 };

      dates[date].used += used;
      dates[date].remaining += remaining;
    });
  });

  const usageByDate = Object.keys(dates)
    .sort()
    .map((date) => [
      parseInt(date, 10),
      dates[date].used,
      dates[date].remaining,
    ]);

  return usageByDate;
}

function mapApiKeyUsageByDate(apiKeyUsage, startDate) {
  const apiKeyDate = new Date(startDate);

  if (apiKeyUsage && !Array.isArray(apiKeyUsage[0]))
    apiKeyUsage = [apiKeyUsage];

  return apiKeyUsage.map((usage) => {
    const date = apiKeyDate.setDate(apiKeyDate.getDate());
    const item = [date, ...usage];
    apiKeyDate.setDate(apiKeyDate.getDate() + 1);
    return item;
  });
}

/* Marketplace integration */

export function confirmMarketplaceSubscription(usagePlanId, token) {
  if (!usagePlanId) {
    return;
  }

  return apiGatewayClient().then((apiGatewayClient) =>
    apiGatewayClient.put(
      "/marketplace-subscriptions/" + usagePlanId,
      {},
      { token: token }
    )
  );
}
