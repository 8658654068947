// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, Image, Icon } from 'semantic-ui-react'

import { isAdmin, isAuthenticated, logout, getLoginRedirectUrl } from 'services/self'

import { cognitoDomain, cognitoClientId } from '../services/api'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// components
import Register from './Register'


export const NavBar = observer(
  class NavBar extends React.Component {
    getCognitoUrl = (type) => {
      let redirectUri = getLoginRedirectUrl()
      return `${cognitoDomain}/${type}?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`
    }

    insertAuthMenu() {
      return isAuthenticated() ?
        (
          <Menu.Menu position="right">
            <Menu.Item><Image src="/custom-content/finchxp.svg" style={{width: "60%" }} /></Menu.Item>
            {isAdmin() && <Menu.Item as={Link} to="/admin">Admin Panel</Menu.Item>}
            <Menu.Item as={Link} to="/dashboard">Overview</Menu.Item>
            <Menu.Item as={Link} to="/authentication">Authentication</Menu.Item>
            <Menu.Header>Solutions</Menu.Header>
            <Menu.Item as={Link} to="/lending">Affordability</Menu.Item>
            <Menu.Item as={Link} to="/pfm">Personal Finance</Menu.Item>
            <Menu.Item as={Link} to="/loyalty">Rewards</Menu.Item>
            <Menu.Item as={Link} to="/open-banking">Open Banking</Menu.Item>
            <Menu.Header>Resources</Menu.Header>
            <Menu.Item key="search" as={Link} to="/apis">API References</Menu.Item>
            <Menu.Item><a className="Link" style={{color: "#fefefe"}} data-btn="true" href="https://docs.finchxp.com/" rel="noreferrer" target="_blank">Documentation</a><Icon name='linkify' style={{paddingTop: "3px"}}/></Menu.Item>
            <Menu.Item><a className="Link" style={{color: "#fefefe"}} data-btn="true" href="https://share.hsforms.com/14bhhxg7TQHO1eFOtG7r2OA33o43" rel="noreferrer" target="_blank">Help Desk</a><Icon name='send' style={{paddingTop: "3px"}}/></Menu.Item>
            <Menu.Item key="signout" as="a" onClick={logout} >Sign Out</Menu.Item>
          </Menu.Menu>
        ) : (
          <Menu.Menu position="right">
            {/* <Register /> */}
            <Menu.Item key="register" as="a"
                       href={this.getCognitoUrl('login')}>
                Log In
            </Menu.Item>
            
          </Menu.Menu>
        )
    }

    render() {
      return <Menu inverted vertical attached style={{ flex: "0 0 auto" }} >
        {this.insertAuthMenu()}
      </Menu >
    }
  }
)
export default NavBar
