// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// semantic-ui
import { Button, Header, Container } from 'semantic-ui-react'

// services
import { subscribe, unsubscribe } from 'services/api-catalog'
import { isAuthenticated } from 'services/self'

import { GetSdkButton } from 'components/GetSdk'

// state
import { observer } from 'mobx-react'
import { store } from 'services/state.js'

// const SubscriptionButtons = observer(class SubscriptionButtons extends React.Component {
//   render() {
//     const { api } = store
//     return (<div>{
//       (api && isAuthenticated() && !api.generic) ? (
//         api.subscribed ? (
//           <button className="ui button" onClick={() => unsubscribe(api.usagePlan.id)}>Unsubscribe</button>
//         ) : (
//           <button className="ui button" onClick={() => subscribe(api.usagePlan.id)} >Subscribe</button>
//         )
//       ) : undefined}</div>
//     )
//   }
// })

// Create the plugin that provides our layout component
export const SwaggerLayoutPlugin = () => ({ components: { 
  InfoContainer: InfoReplacement 
} })

// replaces the InfoContainer component
// https://github.com/swagger-api/swagger-ui/blob/dd3afdc45656bda2a64ae6a7f9bdad006ea98149/src/core/components/layouts/base.jsx

const InfoReplacement = observer(({ specSelectors }) => {
  const info = specSelectors.info()
  const title = info.get('title')
  const version = info.get('version')
  const description = info.get('description')
  const url = specSelectors.url()
  const basePath = specSelectors.basePath()
  const host = specSelectors.host()
  // const externalDocs = specSelectors.externalDocs()

  const parsedUrl = (host ? new URL(basePath, host) : new URL(basePath, url.startsWith('//') ? `https:${url}` : url)).toString()

  return (
    <Container fluid textAlign='left' className="fixfloat" style={{ padding: "10px 0px" }}>
    </Container>
  )
})

export default SwaggerLayoutPlugin