import React from "react";
import { observer } from "mobx-react";
// react-router
import { Link } from "react-router-dom";
import state, { store } from 'services/state.js'
// semantic-ui
import { subscribeUserToUsagePlan } from 'services/api-catalog'
import { Button, Dropdown, Label, Loader, Icon } from "semantic-ui-react";

export const ProductSubscription = observer((props) => {
  const isLoading = !(store.productMetadata && store.productMetadata.products && store.productMetadata.usagePlans && store.subscribedUsagePlans)
  if (isLoading) {
    return (
      <React.Fragment>
        <br></br>
        <Loader size='small' active inline />&nbsp;&nbsp;Loading usage plans
      </React.Fragment>
    )
  }
  const product = store.productMetadata.products.find(p => p.id == props.product)
  
  if (!product) {
    return (
      <React.Fragment>
        No product of this type found
      </React.Fragment>
    )
  }
  const productUsagePlans = store.productMetadata.usagePlans.filter(up => product.usage_plans.includes(up.id))
  
  if (!productUsagePlans || productUsagePlans.length < 1) {
    return (
      <React.Fragment>
        No usage plans found for this product type
      </React.Fragment>
    )
  }

  const subscribedUsagePlanToProduct = (store.subscribedUsagePlans ? store.subscribedUsagePlans.filter(upId => productUsagePlans.some(up => up.id == upId)) : [])

  const subscribeOptions = productUsagePlans.filter(up => up.isVisible && up.isEnabled && up.isPortalSubscribable && !subscribedUsagePlanToProduct.includes(up.id)).map(up => {
    return { 
      key: up.id, 
      text: up.name, 
      value: up.id 
    }
  })

  return (
    <React.Fragment>
      {subscribedUsagePlanToProduct.length > 0 ? (<div>
        <br></br>{
          subscribedUsagePlanToProduct.map(subscribedUsagePlanId => {
            const up = productUsagePlans.find(u => u.id == subscribedUsagePlanId)
            return (
              // <li key={subscribedUsagePlanId}>{up.name}</li>
              <Label active key={subscribedUsagePlanId}><Icon name='check' />{up.name}</Label>
            )
          })
        }
      </div>
      ) : (
        <div>To subscribe to a "Testing" plan, select "Start Testing" below. </div>
      )}

    <br></br>
      {subscribeOptions && subscribeOptions.length > 1 && (
        <Dropdown
          placeholder='Select Usage Plan'
          defaultValue={undefined}
          onChange={(e, f) => {
            subscribeUserToUsagePlan(f.value)
          }}
          selection options={subscribeOptions}
          trigger={<React.Fragment />}
        />
      )}

      {subscribeOptions && subscribeOptions.length == 1 && ( 
        <Button
          onClick={(e, f) => {
          subscribeUserToUsagePlan(subscribeOptions[0].key)
          }}
          >Start Testing</Button>
      )}
      <br></br>

    </React.Fragment>
    
  );
});

export default ProductSubscription;