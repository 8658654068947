// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";

// swagger-ui
import SwaggerUI from "swagger-ui";
import "swagger-ui/dist/swagger-ui.css";

// services
import { subscribe, unsubscribe } from "services/api-catalog";

// semantic-ui
import { Button, Container, Header, Icon, Grid } from "semantic-ui-react";

// services
import { isAuthenticated } from "services/self";
import { updateUsagePlansAndApisList, getApi } from "services/api-catalog";

// components
import SwaggerLayoutPlugin from "components/SwaggerUiLayout";

// state
import { store } from "services/state.js";
import { observer } from "mobx-react";

export default observer(
  class ApisPage extends React.Component {
    componentDidMount() {
      this.updateApi();
    }
    componentDidUpdate() {
      this.updateApi();
    }
    updateApi = () => {
      let swaggerUiConfig = {
        dom_id: "#swagger-ui-container",
        plugins: [SwaggerLayoutPlugin],
        supportedSubmitMethods: ["get", "put", "post", "delete", "options", "head", "patch", "trace"],
        url: `${window.config.xpEndpoint}/api/swagger.json`,
        onComplete: () => {
          if (store.apiKey)
            uiHandler.preauthorizeApiKey("apiKeyAuth", store.apiKey);
        },
      };
      let uiHandler = SwaggerUI(swaggerUiConfig);
    };

    render() {
      return (
        <Grid container style={{ marginTop: "30px" }}>
          <Container>
            <h3>API Reference</h3>
            <br />
            <Grid className="finchcard">
        <Grid.Column width={8} widescreen={8} style={{paddingRight: '30px', marginBottom: "30px"}}>
        <Header as='h1'>Swagger UI</Header>
        <p> 
        For easy testing and integration, we support Swagger UI (see below). 
         </p> 
         <p> 
         Swagger is an API testing tool allowing developers to execute their functional, security, and performance testing right from the OpenAPI Specifications (OAS). For your convenience, we've also created a publicly available <a href="https://github.com/Finchme/finch-xp-data-example" rel="noopener" target="_blank"> github repository</a> with mock data. 
         </p>
        <p> 
            You are welcome to test using your own real data although testing (both fake and real data) is limited to 1000 transactions, 100 users, or 90 days: whichever is first. For more support, you can watch a short video tutorial or contact < a href="https://share.hsforms.com/14bhhxg7TQHO1eFOtG7r2OA33o43" rel="noopener" target="_blank"> suppport team.</a>
          </p>
          </Grid.Column>
        <Grid.Column width={8} widescreen={8}>
          <iframe src="https://player.vimeo.com/video/421401622" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </Grid.Column>
       </Grid>
            <br></br>
            <br></br>
            <Container className="swagger">
              <div
                className="swagger-section"
                style={{ flex: "1 1 auto", overflow: "auto" }}
              >
                <div
                  className="swagger-ui-wrap"
                  id="swagger-ui-container"
                  style={{ padding: "0 20px" }}
                ></div>
              </div>
            </Container>
          </Container>
        </Grid>
      );
    }
  }
);
