// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import {
  Grid,
  Image,
  Header,
  Popup,
  Loader,
  Message,
  Tab,
  List,
  Container,
  Button,
  Table,
  Input,
  Item,
  Checkbox
} from "semantic-ui-react";

import Chart from "chart.js";
import {
  fetchUsage,
  mapUsageByDate,
  createNewApiClient,
  updateApiClient,
  deleteApiClient,
} from "services/api-catalog";
import { toTitleCase } from "services/misc";

import { observer } from "mobx-react";

import { store } from "services/state";

import _ from "lodash";

import CheckboxChecked from "components/Checkbox"

export default observer(() => {
  return (
    <Grid container style={{ marginTop: "30px", marginBottom: "10px" }}>
      <Container>
        <h3>Overview</h3>
           <br />
            <Grid className="finchcard">
                <Header as="h1" style={{ paddingTop: "15px" }}>Welcome to the FinchXP Developer Portal</Header>
                <Grid.Column width={5} widescreen={8}>
                <p>
                The FinchXP Developer Portal is where you’ll find everything you need to manage your API. 
                Follow the steps below to get started.
                </p>
                <Header as="h3">Getting Started Checklist</Header>
                <Item.Group divided>
                <Item>
                    <Item.Content verticalAlign='middle'><CheckboxChecked><input type="checkbox" checked="" class="hidden" readonly="" tabindex="0"/></CheckboxChecked><label style={{paddingLeft: "30px", fontSize:"1em", color: "#1e2783"}}>Create a FinchXP account</label></Item.Content>
                  </Item>
                  <Item>
                    <Item.Content verticalAlign='middle'><Checkbox /><label style={{paddingLeft: "30px", fontSize:"1em", color: "#1e2783"}}>Select a Product in the side menu</label></Item.Content>
                  </Item>
                  <Item>
                    <Item.Content verticalAlign='middle'><Checkbox /><label style={{paddingLeft: "30px", fontSize:"1em", color: "#1e2783"}}>Subscribe to a plan</label></Item.Content>
                  </Item>
                  <Item>
                    <Item.Content verticalAlign='middle'><Checkbox /><label style={{paddingLeft: "30px", fontSize:"1em", color: "#1e2783"}}>Send your first request in Swagger or Postman</label></Item.Content>
                  </Item>
                </Item.Group>
              </Grid.Column>
              {/* <Grid.Column
                width={5}
                widescreen={8}
                style={{ paddingLeft: "50px", marginBottom: '20px'}}
              >
                <Image
                  right
                  src="/custom-content/Welcome.png"
                  width="70%"
                  height="80%"
                  style={{ paddingRight: "0px", marginTop: '50px', verticalAlign: 'middle' }}
                />
              </Grid.Column> */}
            </Grid>
        <br />
        <br />      
        <Grid className="tutorial" style={{marginTop: "10px", paddingBottom: "80px"}}>
        <Grid.Row>
        <Header>Discover FinchXP Products</Header>
        </Grid.Row>
        <Grid.Row style = {{paddingTop: "0px"}}>
        <p>Learn more about FinchXP through our guides and tutorials. Get familiar with our APIs and start building right away.
        </p>      
        </Grid.Row>
          <Grid.Column width={8} widescreen={8}style={{ marginBottom: "20px", marginLeft: "-15px"}}>
            <Header as='h3'>Technical Demo: Lending API</Header>
            <p>
            Go to the Lending API product page to subscribe to a free plan and start testing.  
            </p>
            <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen" data-hj-allow-iframe="" frameborder="0" height="315" src="https://player.vimeo.com/video/456015314" width="560" data-gtm-yt-inspected-30294834_60="true" id="690512011" width="90%" height="100%" ></iframe>
          </Grid.Column>
          <Grid.Column width={2} widescreen={8} style={{ marginBottom: "20px"}}>
          <Header as='h3'>Technical Demo: PFM API</Header>
          <p>
          Go to the Personal Finance API product page to subscribe to a free plan and start testing.  
          </p>
            <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen" data-hj-allow-iframe="" frameborder="0" height="315" src="https://player.vimeo.com/video/456801988" width="560" data-gtm-yt-inspected-30294834_60="true" id="690512011" width="90%" height="100%" ></iframe>
          </Grid.Column>
      </Grid>
      <br /> 
      </Container>
    </Grid>
  );
});


// let renderedCharts = [];
// function renderUsageChart(chart, resourceType) {
//   var options = {
//     spanGaps: false,
//     elements: {
//       line: {
//         tension: 0.4,
//       },
//     },
//     scales: {
//       xAxes: [
//         {
//           stacked: true,
//         },
//       ],
//       yAxes: [
//         {
//           stacked: true,
//         },
//       ],
//     },
//     plugins: {
//       filler: {
//         propagate: false,
//       },
//     },
//   };
//   renderedCharts.forEach((r) => {
//     r.destroy();
//   });
//   renderedCharts = [];
//   setTimeout(() => {
//     const data = JSON.parse(JSON.stringify(store.usageReport.data));
//     const renderedChart = new Chart(chart, {
//       type: "bar",
//       height: 250,
//       data: data.find((d) => d.resourceType === resourceType),
//       options: options,
//     });
//     renderedCharts.push(renderedChart);
//   }, 1);
// }
// function loadResourceUsageChart() {
//   const {
//     dtFrom,
//     dtTo,
//     usagePlans,
//     jobProcessingTypes,
//     productTypes,
//     totalTransactions,
//     totalUsers,
//     totalJobs,
//   } = store.usageReport;
//   return (
//     <div>
//       {/* <h4>
//         Report from {dtFrom.toISODate()} to {dtTo.toISODate()}
//       </h4> */}
//       <Table className="table__api-client-usage-summary">
//         <Table.Header>
//           <Table.Row>
//             <Table.HeaderCell>Usage Plan</Table.HeaderCell>
//             <Table.HeaderCell>Product Type</Table.HeaderCell>
//             <Table.HeaderCell>Transactions</Table.HeaderCell>
//             <Table.HeaderCell>Users</Table.HeaderCell>
//             {jobProcessingTypes.map((processingType) => (
//               <Table.HeaderCell key={processingType}>
//                 Jobs ({processingType})
//               </Table.HeaderCell>
//             ))}
//           </Table.Row>
//         </Table.Header>
//         <Table.Body>
//           {usagePlans.map((usagePlan) =>
//             productTypes.map((productType, idx) => {
//               return (
//                 <Table.Row key={`${usagePlan}--${productType}`}>
//                   {idx === 0 && (
//                     <Table.Cell rowSpan={productTypes.length}>
//                       {usagePlan}
//                     </Table.Cell>
//                   )}
//                   <Table.Cell>{productType}</Table.Cell>
//                   <Table.Cell>
//                     {totalTransactions[usagePlan] &&
//                     totalTransactions[usagePlan][productType]
//                       ? totalTransactions[usagePlan][productType]
//                       : 0}
//                   </Table.Cell>
//                   <Table.Cell>
//                     {totalUsers[usagePlan] && totalUsers[usagePlan][productType]
//                       ? totalUsers[usagePlan][productType]
//                       : 0}
//                   </Table.Cell>
//                   {jobProcessingTypes.map((processingType) => (
//                     <Table.Cell
//                       key={`${usagePlan}--${processingType}--${productType}`}
//                     >
//                       {totalJobs[usagePlan] &&
//                       totalJobs[usagePlan][productType] &&
//                       totalJobs[usagePlan][productType][processingType]
//                         ? totalJobs[usagePlan][productType][processingType]
//                         : 0}
//                     </Table.Cell>
//                   ))}
//                 </Table.Row>
//               );
//             })
//           )}
//         </Table.Body>
//       </Table>
//     </div>
//   );
// }

// function loadUsage(usagePlan, canvasId) {
//   fetchUsage(usagePlan.id)
//     .then((result) => {
//       const data = mapUsageByDate(result.data, "used");
//       const ctx = document.getElementById(canvasId);

//       let oldDataString = JSON.stringify(_.get(usagePlan, "usage.data", {}));
//       let newDataString = JSON.stringify(data);

//       if (oldDataString !== newDataString) usagePlan.usage = { data };

//       const labels = data.map((d) =>
//         new Date(d[0]).toLocaleDateString("en-US", {
//           weekday: "short",
//           year: "numeric",
//           month: "short",
//           day: "numeric",
//         })
//       );
//       const used = data.map((d) => d[1]);
//       const remaining = data.map((d) => d[2]);
//       const max = Math.max(...used, ...remaining);

//       const chartConfig = {
//         type: "line",
//         data: {
//           labels,
//           datasets: [
//             {
//               label:
//                 `Requests used` +
//                 (usagePlan.quota
//                   ? ` (per ${toTitleCase(usagePlan.quota.period)})`
//                   : ""),
//               data: used,
//               lineTension: 0,
//               backgroundColor: "rgba(184, 200, 216, 0.15)",
//               borderColor: "#1B4485",
//               pointBackgroundColor: "transparent",
//               pointBorderColor: "transparent",
//               borderWidth: 2,
//               borderRadius: "10px",
//               pointRadius: 10,
//               pointHoverRadius: 10,
//             },
//             {
//               label:
//                 `Remaining requests` +
//                 (usagePlan.quota
//                   ? ` (per ${toTitleCase(usagePlan.quota.period)})`
//                   : ""),
//               data: remaining,
//               lineTension: 0,
//               backgroundColor: "rgb(65, 190, 192, 0.2)",
//               borderColor: "rgb(65, 190, 192)",
//               pointBackgroundColor: "transparent",
//               pointBorderColor: "transparent",
//               borderRadius: "10px",
//               borderWidth: 2,
//               pointRadius: 10,
//               pointHoverRadius: 10,
//             },
//           ],
//         },
//         options: {
//           scales: {
//             yAxes: [
//               {
//                 ticks: {
//                   beginAtZero: true,
//                   suggestedMax: max + max * 0.02,
//                 },
//               },
//             ],
//           },
//         },
//       };
//       new Chart(ctx, chartConfig);
//     })
//     .catch((error) => {
//       console.error(error);
//       if (!usagePlan.usage) usagePlan.usage = {};

//       usagePlan.usage.error = error;
//     });
// }


          {/* 
            
      <Container className="dashboard">
            <Header>Usage Monitoring</Header>
            <p>
            API usage is monitored daily and there may be limits in place depending on your selected plan.  Your monthly invoice will show details of your monthly usage for the current billing period. For more information on usage limits, please visit our Documentation{" "}
              <a
                href="https://docs.finchxp.com/#topic-API-Overview"
                rel="noopener"
                target="_blank"
              >
                here.
              </a>
            </p>
        </Container>
            
            <Container className="dashboard">
            <Header>Usage Monitoring</Header>
            <p>
              API usage is monitored daily and there may be limits in place
              depending on your selected plan. For more information about usage
              limits for free trial plans, check our Documentation{" "}
              <a
                href="https://docs.finchxp.com/"
                rel="noopener"
                target="_blank"
              >
                here.
              </a>
            </p>
          
           
            {store.usageReport ? (
              <div>
                <Tab
                  className="tabs"
                  renderActiveOnly={true}
                  panes={[
                    {
                      menuItem: "Summary",
                      render: loadResourceUsageChart,
                    },
                    {
                      menuItem: "Transactions",
                      render: () => (
                        <div>
                          <canvas
                            width="400"
                            height="200"
                            id="chart__transactions"
                          ></canvas>
                          {renderUsageChart(
                            "chart__transactions",
                            "transactions"
                          )}
                        </div>
                      ),
                    },
                    {
                      menuItem: "Users",
                      render: () => (
                        <div>
                          <canvas
                            width="400"
                            height="200"
                            id="chart__users"
                          ></canvas>
                          {renderUsageChart("chart__users", "users")}
                        </div>
                      ),
                    },
                    {
                      menuItem: "Jobs",
                      render: () => (
                        <div>
                          <canvas
                            width="400"
                            height="200"
                            id="chart__jobs"
                          ></canvas>
                          {renderUsageChart("chart__jobs", "jobs")}
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            ) : (
              <div>
                <i className="fa fa-circle-notch fa-spin"></i>&emsp;Loading
              </div>
            )}
          </Container>*/}
    
            {/* {(store.usagePlans ? store.usagePlans : []) 
              .filter(
                (usagePlan) => usagePlan.subscribed && usagePlan.apis.length
              )
              .map((usagePlan, index) => {
                let canvasId =
                  `api-usage-chart-container-${usagePlan.id}` + index;

                loadUsage(usagePlan, canvasId);

                return (
                  <Grid.Column
                    width={16}
                    widescreen={8}
                    key={usagePlan.id}
                    style={{ marginBottom: "20px" }}
                  >
                    <Title apis={usagePlan.apis} />
                    {usagePlan.throttle && (
                      <Message info>
                        <p>
                          Requests limited to {usagePlan.throttle.rateLimit} per
                          second, and {usagePlan.throttle.burstLimit} in a
                          burst.
                        </p>
                      </Message>
                    )}
                    {!usagePlan.usage ? (
                      <Loader active />
                    ) : usagePlan.error ? (
                      <Message error content={usagePlan.error.toString()} />
                    ) : null}
                    <canvas id={canvasId} />
                  </Grid.Column>
                );
              })}*/}

const Title = ({ apis }) => {
  let firstApiName = apis[0].swagger.info.title;

  let apiList = (
    <List>
      {apis.reduce(
        (acc, api) =>
          acc.concat(
            <List.Item key={api.id}>{api.swagger.info.title}</List.Item>
          ),
        []
      )}
    </List>
  );

  let extraApiCount = apis.length - 1;

  return (
    <p
      style={{
        fontWeight: "600",
        fontSize: "15px",
        paddightLeft: "40px",
        paddingTop: "30px",
      }}
    >
      Usage for{" "}
      {extraApiCount ? (
        <Popup
          trigger={
            <a style={{ cursor: "pointer" }}>
              {firstApiName} and {extraApiCount} more...
            </a>
          }
          content={apiList}
          on={["hover", "click"]}
          position="right center"
        />
      ) : (
        firstApiName
      )}
    </p>
  );
};
